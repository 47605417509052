import organizer_1 from "../assets/organizers/organizer_1.jpg";
import organizer_2 from "../assets/organizers/organizer_2.jpg";
import speaker_1 from "../assets/speakers/_1.PNG";
import speaker_2 from "../assets/speakers/_2.jpeg";

export default {
  organizer_1,
  organizer_2,
  speaker_1,
  speaker_2,
};
